import PropTypes from 'prop-types';

const Card = props => {
    const { header, children } = props;

    return (
        <div className="card">
            <h3 className="card-header">{header}</h3>

            {children}
        </div>
    );
};

Card.propTypes = {
    header: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
};

export default Card;
