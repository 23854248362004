import PropTypes from 'prop-types';

const HeadersTable = props => {
    const { headers } = props;

    const renderBody = () => {
        if (!headers.length) return null;

        const body = headers.map(({ header, value, id }) => (
            <tr className={header.toLowerCase().replaceAll(' ', '-')} key={id}>
                <td className="header">{header}</td>

                <td className="value">{value}</td>
            </tr>
        ));

        return <tbody className="body">{body}</tbody>;
    };

    return (
        <table className="headers-table">
            <thead className="head">
                <tr>
                    <th className="header">Header</th>

                    <th className="value">Value</th>
                </tr>
            </thead>

            {renderBody()}
        </table>
    );
};

HeadersTable.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired
        })
    )
};

HeadersTable.defaultProps = {
    headers: []
};

export default HeadersTable;
