import { useRef, useState } from 'react';

const FileUpload = () => {
    const uploadRef = useRef(null);
    const [name, setName] = useState('No file has been uploaded');

    const handleClick = () => {
        uploadRef.current.click();
    };

    const handleChange = () => {
        if (uploadRef.current.files[0]) {
            setName(uploadRef.current.files[0].name);
        }
    };

    return (
        <div className="file-upload">
            <button
                type="button"
                onClick={handleClick}
                className="file-upload-btn"
            >
                Upload file
            </button>

            <input
                type="file"
                ref={uploadRef}
                onChange={handleChange}
                className="file-upload-input"
            />

            <p className="file-name">{name}</p>
        </div>
    );
};

export default FileUpload;
