import Card from './components/card/Card';
import FileDownload from './templates/fileDownload/FileDownload';
import FileInput from './templates/fileUpload/FileUpload';
import HeadersTable from './templates/headers/Headers';
import UserAgent from './templates/userAgent/UserAgent';

import './index.scss';

const App = () => (
    <div className="app">
        <Card header="Request Headers">
            <HeadersTable />
        </Card>

        <Card header="User-Agent">
            <UserAgent />
        </Card>

        <Card header="File upload">
            <FileInput />
        </Card>

        <Card header="Download file">
            <FileDownload />
        </Card>
    </div>
);

export default App;
