const config = {
    env: {
        dev: 'dev',
        prod: 'prod'
    },
    dev: {
        apiURL: 'http://localhost:9107'
    },
    prod: {
        apiURL: 'https://testserver.qa.stage.loadero.com'
    }
};

const getEnvironment = () => {
    switch (process.env.NODE_ENV) {
        case 'production':
            return 'prod';
        default:
            return 'dev';
    }
};

const { apiURL } = config[getEnvironment()];

export { apiURL };
