import { StrictMode } from 'react';
import { render } from 'react-dom';
import { unregister } from './serviceWorker';

import App from './App';

render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.getElementById('root')
);

unregister();
