import { useState } from 'react';

import RefreshButton from '../../components/refreshButton/RefreshButton';

const UserAgent = () => {
    const [refresh, setRefresh] = useState(false);

    let ua = 'No user agent was found';
    if (navigator) {
        const { userAgent } = navigator;
        ua = typeof userAgent !== 'undefined' && userAgent;
    }

    const handleClick = () => setRefresh(!refresh);

    return (
        <div className="user-agent">
            <RefreshButton handleClick={handleClick} />

            <p className="user-agent-value">{ua}</p>
        </div>
    );
};

export default UserAgent;
