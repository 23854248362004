import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

const FileDownload = () => {
    const buttonRef = useRef(null);

    const [percentage, setPercentage] = useState(0);
    const [startDownload, setStartDownload] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [downloadError, setDownloadError] = useState({});

    const link =
        'https://tdl-rtc-tester.s3.us-west-2.amazonaws.com/assets/loaderoSample30MB.png';

    useEffect(() => {
        if (!startDownload || percentage > 0) return;

        const onDownloadProgress = ({ loaded, total }) => {
            const uploadProgress = Math.round((loaded / total) * 100);

            if (uploadProgress === percentage) return;
            setPercentage(uploadProgress);
        };

        axios
            .get(
                link,
                {
                    onDownloadProgress,
                    responseType: 'blob'
                },
                () => {}
            )
            .then(response =>
                saveAs(
                    new Blob([response.data]),
                    link.substring(link.lastIndexOf('/') + 1)
                )
            )
            .catch(error => {
                if (!error.response) {
                    setDownloadError({
                        status: 0,
                        data: 'Could not download file'
                    });
                    return;
                }

                const { status, data } = error.response;
                setDownloadError(status, data);
            })
            .finally(() => {
                setDisabled(false);
                setStartDownload(false);
            });
    }, [percentage, startDownload]);

    const handleClick = () => {
        setStartDownload(true);
        setPercentage(0);
        setDisabled(true);
        setDownloadError({});
    };

    return (
        <div className="file-download">
            <button
                type="button"
                className="file-download-btn"
                onClick={handleClick}
                ref={buttonRef}
                disabled={disabled}
            >
                Download
            </button>

            {Object.keys(downloadError).length === 0 ? (
                <p>Download progress: {percentage}%</p>
            ) : (
                <p className="error">
                    Status: {downloadError.status} <br />
                    Data: {downloadError.data}
                </p>
            )}
        </div>
    );
};

export default FileDownload;
