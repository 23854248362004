import { useCallback, useEffect, useState } from 'react';

import { api } from '../../api/config';
import HeadersTable from '../../components/headersTable/HeadersTable';
import RefreshButton from '../../components/refreshButton/RefreshButton';

const Headers = () => {
    const [headers, setHeaders] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const getHeaders = useCallback(() => {
        api.get('/headers')
            .then(({ data }) => {
                const requestHeaders = [];

                if (Object.entries(data).length === 0) return;

                Object.entries(data).forEach(([header, value], id) => {
                    requestHeaders.push({ header, value, id });
                });

                setHeaders(requestHeaders);
            })
            .catch(() => {});
    }, []);

    useEffect(() => getHeaders(), [getHeaders, refresh]);

    const handleClick = () => setRefresh(!refresh);

    return (
        <div className="headers">
            <RefreshButton handleClick={handleClick} />

            {headers.length > 0 ? (
                <HeadersTable headers={headers} />
            ) : (
                <p>No headers were found</p>
            )}
        </div>
    );
};

export default Headers;
