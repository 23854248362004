import PropTypes from 'prop-types';

const RefreshButton = props => {
    const { handleClick } = props;

    return (
        <button
            className="refresh-button"
            onClick={handleClick}
            type="button"
            aria-label="Refresh"
        />
    );
};

RefreshButton.propTypes = {
    handleClick: PropTypes.func.isRequired
};

export default RefreshButton;
